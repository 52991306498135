
import { Swiper, SwiperCore, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { Pagination, Mousewheel } from "swiper";

SwiperCore.use([Pagination, Mousewheel]);

export default {
  name: "UiSwiperCarousel",
  components: { Swiper, SwiperSlide },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    slidesPerView: {
      type: [String, Number],
      default: 4.5,
    },
    slidesPerViewMobile: {
      type: [String, Number],
      default: 2.2,
    },
    spaceBetween: {
      type: [String, Number],
      default: 40,
    },
    spaceBetweenMobile: {
      type: [String, Number],
      default: 10,
    },
  },
  data() {
    return {
      sliderInstance: null,
      pagination: false,
    };
  },
  computed: {
    swiperOptions() {
      return {
        slidesPerView: this.slidesPerViewMobile,
        spaceBetween: this.spaceBetweenMobile,
        roundLengths: true,
        pagination: this.pagination,
        resistanceRatio:
          this.slides.length > this.slidesPerViewMobile ? 0.85 : 0,
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },
        breakpoints: {
          992: {
            spaceBetween: this.spaceBetween,
            slidesPerView: this.slidesPerView,
            resistanceRatio: this.slides.length > this.slidesPerView ? 0.85 : 0,
          },
        },
      };
    },
    currentSlidesPerView() {
      return this.sliderInstance
        ? this.sliderInstance.params.slidesPerView
        : this.slidesPerViewMobile;
    },
    showNavigation() {
      return this.slides?.length > this.currentSlidesPerView;
    },
  },
  mounted() {
    this.sliderInstance = this.$refs.carousel?.swiperRef;
    this.pagination = {
      el: this.$refs.pagination,
      clickable: true,
      bulletClass: "ui-swiper-pagination-bullet",
      bulletActiveClass: "ui-swiper-pagination-bullet-active",
      dynamicBullets: true,
      dynamicMainBullets: 2,

      renderBullet: (index, className) => {
        return `<li class="${className}"><button><span class="sr-only">${this.$t(
          "ariaGoToSlide",
          { index: index + 1 }
        )}</span></button></li>`;
      },
    };
  },
};
