
import { setAttributeToElements } from "~/utils";
import UiSwiperCarousel from "~/components/UI/UiSwiperCarousel.vue";
export default {
  name: "InstagramBlock",
  components: {
    UiSwiperCarousel,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    blockData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      images: [],
    };
  },
  created() {
    if (this.data?.pictures?.length) {
      this.images = this.data.pictures.map((item) => ({
        ...item,
        error: false,
      }));
    }
  },
  mounted() {
    setAttributeToElements("a.hidden-ssr-link", "tabindex", "0");
  },
  methods: {
    imageError(item) {
      item.error = true;
    },
  },
};
